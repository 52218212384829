import { ArtistList, ShopsList } from '@imus/release-page-template';
import type { InferGetStaticPropsType } from 'next';
import { fetchReleasePageStaticData } from '@/shared/fetchStaticData';
import { ReleasePageLayout } from '@/components/Layout';

// TODO: bring back no shops
export default function HomePage({
  data,
  pageId,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  // _props: InferGetStaticPropsType<typeof getStaticProps>
  return (
    <ReleasePageLayout data={data} pageId={pageId}>
      <ArtistList data={data} />
      <ShopsList data={data} />
    </ReleasePageLayout>
  );
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  };
}

export const getStaticProps = fetchReleasePageStaticData;
